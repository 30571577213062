@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  color: #003366;
  font-family: "Inter", sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bg-dark {
  background-color: #003366 !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.markdown-styles {}

.markdown-styles p {
  /* font-size: 16px; */
  /* font-weight: bold; */
  margin-bottom: 12px;

}

.markdown-styles h1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 18px;
  margin-top: 16px;

}

.markdown-styles h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 16px;

}

.markdown-styles h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 32px;
}
.text-content > h3:first-child, .text-content > h4:first-child{
  margin-top:0;
  margin-bottom:12px;
}

.markdown-styles h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 16px;

}

.markdown-styles h5 {
  font-size: 14px;
  font-weight: bold;
  margin-top: 16px;

}

.bubble {
  position: relative;
}

.bubble::before {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid white;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

.bubble::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
  background-color: #F4FBFD;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
  margin-bottom: 12px;
}